@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';

.rootWithSquareImage {
  padding-top: spacing.$spacing-16;
  padding-bottom: spacing.$spacing-16;

  @include breakpoints.media(mobile-landscape) {
    padding-top: spacing.$spacing-24;
  }

  @include breakpoints.media(tablet) {
    padding-top: spacing.$spacing-48;
  }

  @include breakpoints.media(tablet-landscape) {
    padding-top: spacing.$spacing-80;
    padding-bottom: spacing.$spacing-40;
  }
}

.rootWithNoSquareImage {
  display: flex;
  align-items: flex-end;
  padding-top: spacing.$spacing-24;
  padding-bottom: spacing.$spacing-24;

  aspect-ratio: 100 / 96;

  @include breakpoints.media(mobile-landscape) {
    aspect-ratio: 100 / 55;
  }

  @include breakpoints.media(tablet-landscape) {
    aspect-ratio: 100 / 40;
  }

  @include breakpoints.media(desktop) {
    aspect-ratio: 100 / 30;
  }
}

%placeholder {
  background-color: $yds-color-background-interactive;
  max-width: 100%;
  animation: 1s pulse infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.999;
  }
  50% {
    opacity: 0.8;
  }
}

.image {
  @extend %placeholder;
  margin-bottom: spacing.$spacing-32;

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.textContent {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.highlightLabel {
  @extend %placeholder;
  width: 7rem;
  height: 1.375rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  animation-delay: 0.2s;
}

.title {
  @extend %placeholder;
  width: 30rem;
  height: 32px;
  margin-bottom: 0.5rem;
  animation-delay: 0.4s;

  @include breakpoints.media(tablet) {
    height: 42px;
  }

  @include breakpoints.media(desktop-small) {
    height: 50px;
  }
}

.description {
  @extend %placeholder;
  width: 20rem;
  height: 1.5rem;
  margin-bottom: 1.5rem;
  animation-delay: 0.6s;
}

.control {
  @extend %placeholder;
  width: 8rem;
  height: 40px;
  border-radius: 999px;
  animation-delay: 0.8s;

  @include breakpoints.media(desktop-small) {
    height: 41px;
  }
}
