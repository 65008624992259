@use 'styles/breakpoints';
@use 'styles/spacing';

$smallGutter: 16px;
$largeGutter: 24px;

.list {
  display: grid;
  gap: $smallGutter;
  list-style: none;
  margin: 0;
  min-width: 0;
  padding: 0;
  position: relative;

  @include breakpoints.media(desktop-small) {
    gap: $largeGutter;
  }

  &WithNarrowImages {
    grid-template-columns: repeat(2, minmax(10px, 1fr));

    @include breakpoints.media(mobile-landscape) {
      grid-template-columns: repeat(3, minmax(10px, 1fr));
    }
    @include breakpoints.media(tablet) {
      grid-template-columns: repeat(4, minmax(10px, 1fr));
    }
    @include breakpoints.media(tablet-landscape) {
      grid-template-columns: repeat(5, minmax(10px, 1fr));
    }
    @include breakpoints.media(desktop) {
      grid-template-columns: repeat(6, minmax(10px, 1fr));
    }
  }

  &WithWideImages {
    grid-template-columns: repeat(2, minmax(10px, 1fr));

    @include breakpoints.media(mobile-landscape) {
      grid-template-columns: repeat(3, minmax(10px, 1fr));
    }
    @include breakpoints.media(desktop-small) {
      grid-template-columns: repeat(4, minmax(10px, 1fr));
    }
  }
}

.loadMoreButton {
  display: flex;
  flex-direction: column;
  margin-top: spacing.$spacing-16;
}
