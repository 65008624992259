@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use 'styles/breakpoints';
@use 'styles/typography';
@use 'styles/spacing';
@use 'styles/cardStyles';

.card {
  position: relative;
  z-index: 0; // create local stacking contect, does not change the stack level in current stacking context
  text-align: start;

  &Active {
    background-color: rgba($yds-color-feedback-highlight, 0.24);
  }

  &Vertical {
    align-items: center;
    display: flex;
  }
}

.imageContentWrapper {
  position: relative;

  .cardVertical & {
    align-self: flex-start;
    flex: none;
  }
}

.withAspectRatio16_9 {
  .cardVertical & {
    @include cardStyles.cardWidthVertical;
  }
}

.withAspectRatio1_1 {
  .cardVertical & {
    @include cardStyles.cardWidthVerticalWithAspectRatio_1_1;
  }
}

.highlightWrapper {
  position: absolute;
  top: spacing.$spacing-6;
  left: spacing.$spacing-6;
}

.verticalHighlightWrapper {
  padding-bottom: spacing.$spacing-8;
}

.progressBarWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.buttonWrapper {
  position: absolute;
  right: spacing.$spacing-8;
  bottom: spacing.$spacing-8;
  z-index: 1;

  .cardVertical & {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.textContentWrapper {
  padding-top: spacing.$spacing-8;
  padding-bottom: spacing.$spacing-8;

  .cardVertical & {
    flex-grow: 1;
    min-width: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .cardVertical .imageContentWrapper + & {
    padding-left: spacing.$spacing-8;

    @include breakpoints.media(mobile-landscape) {
      padding-left: spacing.$spacing-16;
    }

    @include breakpoints.media(desktop-small) {
      padding-left: spacing.$spacing-24;
    }
  }
}

.title {
  @include typography.default-m-bold;
  margin-top: 0;
  margin-bottom: 0;

  .cardVertical & {
    @include breakpoints.media(desktop) {
      @include typography.default-l-bold;
    }
  }
}

@mixin textIn2Lines {
  -webkit-line-clamp: 2;
}

@mixin textIn3Lines {
  -webkit-line-clamp: 3;
}

@mixin textIn1Lines {
  -webkit-line-clamp: 1;
}

.audioTitleLink {
  @include textIn2Lines;

  @include breakpoints.media(tablet-landscape) {
    @include textIn3Lines;
  }
}

.titleLink {
  @include textIn3Lines;
}

.link {
  display: -webkit-box;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

button.link {
  // Make buttons feel like link as .link can also be a button
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

.description {
  @include typography.default-s;
  color: $yds-color-gray-40;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: spacing.$spacing-4;
  margin-bottom: 0;

  .cardVertical & {
    display: none;
    margin-top: spacing.$spacing-8;

    @include breakpoints.media(desktop-small) {
      display: -webkit-box;
    }
    @include breakpoints.media(desktop) {
      @include typography.default-m;
    }
  }
}

.broadcastCardLabels {
  margin-top: 0;
  margin-bottom: spacing.$spacing-4;
}

.labels {
  margin-top: spacing.$spacing-4;

  .cardVertical & {
    margin-top: spacing.$spacing-8;
  }
}

.controlWrapper {
  flex: none;
  min-width: 2.75rem;
}

.arrowWrapper {
  flex: none;
  padding: spacing.$spacing-10;
}

.arrowIcon {
  color: $yds-color-gray-40;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}

.packageCard {
  .textContentWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: spacing.$spacing-24;
    padding-right: spacing.$spacing-24;
    text-align: center;
  }
}

.roundedCorners {
  border-radius: $yds-border-radius-large;
  overflow: hidden;
}
