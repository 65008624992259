@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.root {
  position: relative;
  display: grid;
  grid-template-areas: 'cover';

  // Create a new stacking context
  z-index: 0;
}

.backgroundImage {
  position: relative;
  grid-area: cover;
  z-index: -1;

  .rootWithNoSquareImage & {
    aspect-ratio: 100 / 96;

    @include breakpoints.media(mobile-landscape) {
      aspect-ratio: 100 / 55;
    }

    @include breakpoints.media(tablet-landscape) {
      aspect-ratio: 100 / 40;
    }

    @include breakpoints.media(desktop) {
      aspect-ratio: 100 / 30;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(
        rgba(19, 20, 21, 0) 83.91%,
        $yds-color-background
      ),
      linear-gradient(
        0.75turn,
        rgba(26, 27, 28, 0.15) 28.86%,
        rgba(26, 27, 28, 0.8) 75.83%
      );
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center top;
  }
}

.content {
  align-self: flex-end;
  grid-area: cover;
  padding-top: spacing.$spacing-24;
  padding-bottom: spacing.$spacing-24;

  .rootWithSquareImage & {
    padding-top: spacing.$spacing-16;
    padding-bottom: spacing.$spacing-16;

    @include breakpoints.media(mobile-landscape) {
      padding-top: spacing.$spacing-24;
    }

    @include breakpoints.media(tablet) {
      padding-top: spacing.$spacing-48;
    }

    @include breakpoints.media(tablet-landscape) {
      padding-top: spacing.$spacing-80;
      padding-bottom: spacing.$spacing-40;
    }
  }
}

.image {
  margin-bottom: spacing.$spacing-32;
  padding-top: 100%; // = aspect ratio 1/1
  position: relative;

  @include breakpoints.media(mobile-landscape) {
    margin-bottom: 0;
  }
}

.textContent {
  display: flex;
  max-width: 618px;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.highlightLabels {
  margin-bottom: spacing.$spacing-8;
}

.title {
  margin-top: 0;
  margin-bottom: 0;

  @include typography.default-2xl-heavy;

  @include breakpoints.media(tablet) {
    @include typography.default-4xl-heavy;
  }

  @include breakpoints.media(desktop-small) {
    @include typography.default-5xl-heavy;
  }
}

.link {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
a.link {
  text-decoration: none;
}
button.link {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
}

.description {
  @include typography.default-m;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin-top: spacing.$spacing-8;
  margin-bottom: 0;
}

.controls {
  /**
    Needs to be positioned because interactive elements have to
    be lifted above the link overlay
  */
  position: relative;

  margin-top: spacing.$spacing-24;
}
